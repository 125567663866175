import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Card from '../interfaces/card.interface';

import './VideoPage.css';

const VideoPage: React.FC = () => {
  const { cardId } = useParams<{ cardId: string }>();

  const [data, setData] = useState<Card | null>(null);

  useEffect(() => {
    fetch(`https://api.rutas.civischile.cl/api/routes/video/${cardId}`)
      .then((response) => response.json())
      .then((data: Card) => setData(data))
      .catch((error) => console.error('Error:', error));  
  }, []);

  if (!data) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="video-page">
      <div className="video-container">
        <iframe
          className="iframe-video"
          src={data.videoLink}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <p>{data.totem!.message}</p>
    </div>
  );
  
};

export default VideoPage;